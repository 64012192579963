<template>
  <div class="ad-consume padding-20">
    <!-- 搜索条件 -->
    <div class="filter-box">
      <div class="row">
        <el-date-picker
          v-model="params.queryDate"
          value-format="yyyy-MM-dd"
          type="date"
          style="width: 150px"
          :clearable="false"
          placeholder="选择时间"
          :picker-options="disableTodayOptions"
        >
        </el-date-picker>
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button type="board" @click="handlePageChange"> 搜索 </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>

    <el-row :gutter="5" class="board-main">
      <!-- 左边部分 -->
      <el-col :span="7">
        <!-- 今日投放 -->
        <div class="today-put">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">今日投放</span>
            </div>
            <el-row :gutter="10" style="margin-top: 10px">
              <el-col :span="12">
                <div
                  class="put-item put-item-goto"
                  @click="handleShowDrawer('channelConsume')"
                >
                  <h4 class="put-1">现金投放</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ cashCostAmt }}万</span>
                    <template v-if="cashCostAmtMounting > 0">
                      <span class="change-value-red">
                        <i class="iconfont icon-shangsheng"></i
                        ><span>{{ cashCostAmtMounting }}%</span></span
                      >
                    </template>
                    <template v-else>
                      <span class="change-value-green">
                        <i class="iconfont icon-xiajiang"></i
                        ><span>{{ cashCostAmtMounting }}%</span></span
                      >
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="put-item put-item-goto"
                  @click="handleShowDrawer('channelConsume')"
                >
                  <h4 class="put-2">公众号</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ channelNum }}</span>
                    <template v-if="channelNumMounting > 0">
                      <span class="change-value-red">
                        <i class="iconfont icon-shangsheng"></i
                        ><span>{{ channelNumMounting }}</span></span
                      >
                    </template>
                    <template v-else>
                      <span class="change-value-green">
                        <i class="iconfont icon-xiajiang"></i
                        ><span>{{ channelNumMounting }}</span></span
                      >
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="put-item put-item-goto"
                  @click="handleShowDrawer('bookConsume')"
                >
                  <h4 class="put-3">书籍</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ booksNum }}</span>
                    <template v-if="booksNumMounting > 0">
                      <span class="change-value-red">
                        <i class="iconfont icon-shangsheng"></i
                        ><span>{{ booksNumMounting }}</span></span
                      >
                    </template>
                    <template v-else>
                      <span class="change-value-green">
                        <i class="iconfont icon-xiajiang"></i
                        ><span>{{ booksNumMounting }}</span></span
                      >
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="put-item put-item-goto"
                  @click="handleShowDrawer('adConsume')"
                >
                  <h4 class="put-4">广告</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ adNum }}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <!-- 矩形树 -->
        <div class="map-tree-box">
          <el-row :gutter="2">
            <el-col :span="12">
              <p
                class="title"
                :style="
                  'color:' + subTitleColor + ';font-size:' + subTitleFontSize
                "
              >
                书籍消耗分布
              </p>
              <div id="bookDistribution"></div>
            </el-col>
            <el-col :span="12">
              <p
                class="title"
                :style="
                  'color:' + subTitleColor + ';font-size:' + subTitleFontSize
                "
              >
                公众号消耗分布
              </p>
              <div id="channelDistribution"></div>
            </el-col>
          </el-row>
        </div>
        <!-- 今日消息 -->
        <div class="today-message">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">今日消息</span>
            </div>
            <el-row :gutter="10" style="margin-top: 10px">
              <el-col :span="12">
                <div
                  class="put-item"
                  style="cursor: pointer"
                  @click="handleShowDrawer('customerMessage')"
                >
                  <h4 class="put-1">消息</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ customerMessageNum }}</span>
                    <template v-if="customerMessageNum > 0">
                      <span class="change-value-red">
                        <i class="iconfont icon-shangsheng"></i
                        ><span>{{ customerMessageMounting }}</span></span
                      >
                    </template>
                    <template v-else>
                      <span class="change-value-green">
                        <i class="iconfont icon-xiajiang"></i
                        ><span>{{ customerMessageMounting }}</span></span
                      >
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="put-item">
                  <h4 class="put-2">发送客户</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ sendMessageNum }}万</span>
                    <template v-if="sendMessageMounting > 0">
                      <span class="change-value-red">
                        <i class="iconfont icon-shangsheng"></i
                        ><span>{{ sendMessageMounting }}%</span></span
                      >
                    </template>
                    <template v-else>
                      <span class="change-value-green">
                        <i class="iconfont icon-xiajiang"></i
                        ><span>{{ sendMessageMounting }}%</span></span
                      >
                    </template>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
        <!-- 七日时段对比图 -->
        <div class="bar-box">
          <el-row :gutter="0" style="margin-top: 10px">
            <el-col :span="24">
              <div id="sevenCompare"></div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <!-- 中间部分 -->
      <el-col :span="9">
        <!-- 今日充值 -->
        <div class="today-charge">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="cursor: pointer">
              <span class="card-title drawer-text" @click="showDuPont = true"
                >今日充值</span
              >
            </div>
            <!-- bar1 -->
            <el-row :gutter="5" style="margin-top: 10px">
              <el-col :span="23">
                <div
                  class="charge-bar charge-bar-goto"
                  @click="handleShowDrawer('operateData')"
                >
                  <div
                    class="bar-item1"
                    :class="item.name === '当日充值' ? 'bar-item-goto' : ''"
                    style="line-height: 25px"
                    v-for="(item, index) in todyChargeBar1"
                    :key="index"
                  >
                    <p class="title">{{ item.name }}</p>
                    <p
                      class="value"
                      :class="item.name.trim() === '增长率' ? 'returnRate' : ''"
                    >
                      {{ item.value }}{{ item.name.includes("率") ? "%" : "" }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- bar2 -->
            <el-row
              :gutter="5"
              type="flex"
              justify="center"
              style="margin-top: 10px"
            >
              <el-col :span="20">
                <div class="charge-bar">
                  <div
                    class="bar-item2"
                    style="line-height: 20px"
                    v-for="(item, index) in todyChargeBar2"
                    :key="index"
                  >
                    <p class="title">{{ item.name }}</p>
                    <p
                      class="value"
                      :data-test="item.name.trim()"
                      :class="item.name.trim() === '回本率' ? 'returnRate' : ''"
                    >
                      {{ item.value }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- 回本率刻度尺 -->
            <el-row
              :gutter="5"
              type="flex"
              justify="center"
              style="margin-top: 10px"
            >
              <el-col :span="20">
                <PercentageRuler
                  :percentage="parseInt(todyreturnRate)"
                  :stroke-width="10"
                />
              </el-col>
            </el-row>
            <!-- 会员 客户分布饼状图 -->
            <el-row :gutter="5" type="flex" justify="space-around">
              <el-col :span="12">
                <div id="vipChargePie"></div>
              </el-col>
              <el-col :span="12">
                <div id="customerTypePie"></div>
              </el-col>
            </el-row>
            <!-- 近十五日d1回本率 -->
            <el-row :gutter="5">
              <el-col :span="24">
                <div id="fifteenFirstReturn"></div>
              </el-col>
            </el-row>
            <!-- 近十五日充值构成 -->
            <el-row :gutter="5">
              <el-col :span="24">
                <div id="fifteenFifteenRechargeAmt"></div>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
      <!-- 右侧部分 -->
      <el-col :span="8">
        <!-- 累计回本 -->
        <div class="total-return">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">
                <h4 class="title">累计回本</h4>
                <div class="card-title-tab" @click="handleTotalReturnTabChange">
                  <span
                    data-id="1"
                    :class="totalReturnTab === 1 ? 'active' : ''"
                    >累计</span
                  >
                  <span
                    data-id="2"
                    :class="totalReturnTab === 2 ? 'active' : ''"
                    >上月</span
                  >
                  <span
                    data-id="3"
                    :class="totalReturnTab === 3 ? 'active' : ''"
                    >本月</span
                  >
                  <span
                    data-id="4"
                    :class="totalReturnTab === 4 ? 'active' : ''"
                    >近一月</span
                  >
                </div>
              </span>
            </div>
            <!-- bar1 -->
            <el-row
              :gutter="5"
              type="flex"
              justify="center"
              style="margin-top: 10px"
            >
              <el-col :span="24">
                <div class="charge-bar">
                  <div
                    class="bar-item3"
                    style="line-height: 25px"
                    v-for="(item, index) in totalReturnRoi"
                    :key="index"
                  >
                    <p class="title">{{ item.name }}</p>
                    <p
                      class="value"
                      :class="item.name.trim() === 'roi' ? 'returnRate' : ''"
                    >
                      {{ formatThousandsOne(item.value)
                      }}{{ item.name.includes("roi") ? "%" : "万" }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- 月度roi -->
            <el-row :gutter="0" style="margin-top: 10px">
              <el-col :span="24">
                <div id="MonthRoi"></div>
              </el-col>
            </el-row>
            <!-- 书籍roi -->
            <el-row :gutter="0" style="margin-top: 10px" class="books-roi">
              <el-col :span="24">
                <div class="title">
                  <span
                    class="title-text"
                    :style="
                      'color:' +
                      subTitleColor +
                      ';font-size:' +
                      subTitleFontSize
                    "
                    >书籍ROI(万)</span
                  >
                  <el-button-group class="change-page">
                    <span class="page-value"
                      >{{ booksPage }}/{{ booksRoiTotalPage }}</span
                    >
                    <el-button
                      size="mini"
                      icon="el-icon-arrow-left"
                      style="
                        border-radius: 5px 0 0 5px;
                        background-color: #c0ccda;
                      "
                      @click="handleTablePageChange('back', 'books')"
                    ></el-button>
                    <el-button
                      size="mini"
                      style="
                        border-radius: 0 5px 5px 0;
                        background-color: #c0ccda;
                      "
                      @click="handleTablePageChange('go', 'books')"
                      ><i class="el-icon-arrow-right el-icon--right"></i
                    ></el-button>
                  </el-button-group>
                </div>
              </el-col>
              <el-col :span="24">
                <el-table
                  :data="booksRoiPageData"
                  height="180"
                  @sort-change="handleBookSortChange"
                >
                  <el-table-column
                    label="书籍"
                    min-width="50"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <div>
                        {{ scope.row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="投放"
                    prop="putAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <el-table-column
                    label="回本"
                    prop="returnAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <el-table-column
                    label="利润"
                    prop="profitAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <!-- <el-table-column
                    label="回本率"
                    prop="roi"
                    min-width="66"
                    show-overflow-tooltip
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <span>{{ formatThousandsOne(scope.row.roi) }}%</span>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    label="ROI"
                    width="120"
                    prop="roi"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      {{ formatThousandsOne(scope.row.roi) }}%
                      <el-progress
                        :percentage="getpercentage(scope.row)"
                        color="#579BF4"
                        :show-text="false"
                      ></el-progress>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
            <!-- 公众号roi -->
            <el-row :gutter="0" style="margin-top: 15px" class="books-roi">
              <el-col :span="24">
                <div class="title">
                  <span
                    class="title-text"
                    :style="
                      'color:' +
                      subTitleColor +
                      ';font-size:' +
                      subTitleFontSize
                    "
                    >公众号ROI(万)</span
                  >
                  <el-button-group class="change-page">
                    <span class="page-value"
                      >{{ channelsPage }}/{{ channelsRoiTotalPage }}</span
                    >
                    <el-button
                      size="mini"
                      style="
                        border-radius: 5px 0 0 5px;
                        background-color: #c0ccda;
                      "
                      @click="handleTablePageChange('back', 'channels')"
                      icon="el-icon-arrow-left"
                    ></el-button>
                    <el-button
                      size="mini"
                      style="
                        border-radius: 0 5px 5px 0;
                        background-color: #c0ccda;
                      "
                      @click="handleTablePageChange('go', 'channels')"
                      ><i class="el-icon-arrow-right el-icon--right"></i
                    ></el-button>
                  </el-button-group>
                </div>
              </el-col>
              <el-col :span="24">
                <el-table
                  :data="channelsRoiPageData"
                  @sort-change="handleChannelSortChange"
                  height="180"
                >
                  <el-table-column
                    label="公众号"
                    min-width="50"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <div>
                        {{ scope.row.name }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="投放"
                    prop="putAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <el-table-column
                    label="回本"
                    prop="returnAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <el-table-column
                    label="利润"
                    prop="profitAmt"
                    min-width="54"
                    sortable="custom"
                  ></el-table-column>
                  <!-- <el-table-column
                    label="回本率"
                    min-width="66"
                    show-overflow-tooltip
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <span>{{ formatThousandsOne(scope.row.roi) }}%</span>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    label="ROI"
                    prop="roi"
                    sortable="custom"
                    width="120"
                  >
                    <template slot-scope="scope">
                      {{ formatThousandsOne(scope.row.roi) }}%
                      <el-progress
                        :percentage="getpercentage(scope.row)"
                        color="#579BF4"
                        :show-text="false"
                      ></el-progress>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <!-- 杜邦图 -->
    <el-drawer
      class="duPont-drawer"
      title="小说投放概况(杜邦图分析)"
      :visible.sync="showDuPont"
      @close="showDuPont = false"
      size="100%"
      center
    >
      <DuPontPicture :show="showDuPont" :lastParams="params" />
    </el-drawer>
  </div>
</template>

<script>
import echarts from "echarts";
import { mapActions } from "vuex";
import { getBookList } from "@/api/account";
import PercentageRuler from "../components/ruler";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import DuPontPicture from "@/components/duPontPicture";
import {
  formatThousandsOne,
  formatTime,
  getPreMonth,
  getPresentMonth,
  getRecentlyMonth,
} from "@/assets/js/utils";
import { scrollTo } from "@/assets/js/scrollTo";
import {
  operateManagerPutRecharge,
  operateManagerSevenDayData,
  operateFifteenFirstReturn,
  operateFifteenFifteenRechargeAmt,
  operateFifteenBCVCCost,
  operateTotalReturnRoi,
  operateMonthRoi,
} from "@/api/monitor";
import { disableTodayOptions } from "@/assets/js/options";
export default {
  data() {
    return {
      formatThousandsOne,
      formatTime,
      costChart: null,
      mapChart: null,
      barChart: null,
      sevenComparebar: null,
      params: {},
      bookList: null,
      // 今日投放相关
      todayPut: [],
      cashCostAmt: null,
      cashCostAmtMounting: null,
      channelNum: null,
      channelNumMounting: null,
      booksNum: null,
      booksNumMounting: null,
      adNum: null,
      // 书籍 公众号分布
      booksCost: null,
      channelCost: null,
      // 今日消息相关
      customerMessageNum: null,
      customerMessageMounting: null,
      sendMessageNum: null,
      sendMessageMounting: null,
      // 七日对比图
      sevenCompareData: null,
      // 今日充值
      todyChargeBar1: [],
      todyChargeBar2: [],
      // 回本率刻度尺
      todyreturnRate: 0,
      // 客户类型 会员充值分布
      vipChargePie: null,
      customerTypePie: null,
      // 十五日回本线性图
      fifteenFirstReturn: null,
      // 近十五日充值构成
      fifteenFifteenRechargeAmt: null,
      // 累计回本roi
      totalReturnRoi: [],
      totalReturnTab: 1,
      // 月度roi
      MonthRoi: null,
      // 表格相关
      booksRoi: [],
      booksPage: 1,
      booksRoiPageSize: 20,
      booksRoiTotalPage: 0,
      booksRoiPageData: [],
      channelsRoi: [],
      channelsPage: 1,
      channelsRoiPageSize: 20,
      channelsRoiTotalPage: 0,
      channelsRoiPageData: [],
      sortName: null,
      sortDesc: null,
      dataType: null,
      // echart同一样式
      subTitleColor: "#969696",
      subTitleFontSize: "12px",
      // 抽屉相关
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      // 杜邦图
      showDuPont: false,
      // 部分新增时间筛选
      disableTodayOptions,
      // partQueryDate: formatTime(new Date(), "yyyy-MM-dd"),
    };
  },
  components: {
    PercentageRuler,
    SelectPlatformChannel,
    DuPontPicture,
  },
  methods: {
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    handleTest() {
      scrollTo(0, 800);
    },

    // 点击跳转相关
    handleGoDuBang() {
      // ;
    },
    handleShowDrawer(type, timeTpe = "date") {
      this.showTemplate = type;
      let tmpQuery =
        timeTpe === "date"
          ? this.params.queryDate
          : this.params.queryDate + " 23:59:59";
      // : formatTime(new Date(), "yyyy-MM-dd 23:59:59");
      this.nextParams = {
        ...this.params,
        queryDate: tmpQuery,
      };
      this.showCommonDrawer = true;
    },
    // 公众号表格排序处理
    handleBookSortChange({ column, prop, order }) {
      this.dataType = 1;
      if (order === "descending") {
        this.sortName = prop;
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortName = prop;
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    // 公众号表格排序处理
    handleChannelSortChange({ column, prop, order }) {
      this.dataType = 2;
      if (order === "descending") {
        this.sortName = prop;
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortName = prop;
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    // 累计回本tab切换
    handleTotalReturnTabChange(e) {
      this.totalReturnTab = parseInt(e.target.dataset.id);
      this.getoperateTotalReturnRoi(this.totalReturnTab);
    },
    // 累计回本时间段选择
    handleChangeTotalReturnRoi() {},
    // 表格静态分页
    tableStaticPage(page, type) {
      if (type === "books") {
        this.booksRoiPageData = this.booksRoi.slice(
          this.booksRoiPageSize * (page - 1),
          this.booksRoiPageSize * page
        );
      } else {
        this.channelsRoiPageData = this.channelsRoi.slice(
          this.channelsRoiPageSize * (page - 1),
          this.channelsRoiPageSize * page
        );
      }
    },
    // 分页点击事件
    handleTablePageChange(flag, type) {
      if (type === "books") {
        if (flag === "go") {
          this.booksPage =
            this.booksPage <
            Math.ceil(this.booksRoi.length / this.booksRoiPageSize)
              ? this.booksPage + 1
              : this.booksPage;
        } else if (flag === "back") {
          this.booksPage =
            this.booksPage > 1 ? this.booksPage - 1 : this.booksPage;
        }
        this.tableStaticPage(this.booksPage, type);
      } else if (type === "channels") {
        if (flag === "go") {
          this.channelsPage =
            this.channelsPage <
            Math.ceil(this.channelsRoi.length / this.channelsRoiPageSize)
              ? this.channelsPage + 1
              : this.channelsPage;
        } else if (flag === "back") {
          this.channelsPage =
            this.channelsPage > 1 ? this.channelsPage - 1 : this.channelsPage;
        }
        this.tableStaticPage(this.channelsPage, type);
      }
    },
    // 表格进度条
    getpercentage(row) {
      return row.roi >= 100 ? 100 : row.roi;
    },
    // 重置
    handleReset() {
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
      this.sortName = null;
      this.sortDesc = null;
      this.initPage();
    },
    // 查询操作
    handlePageChange() {
      this.initPage();
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // echarts相关------------------------------------------------>
    // 初始化echart
    initEchart() {
      this.bookDistribution = echarts.init(
        document.getElementById("bookDistribution")
      );
      this.channelDistribution = echarts.init(
        document.getElementById("channelDistribution")
      );
      this.sevenComparebar = echarts.init(
        document.getElementById("sevenCompare")
      );
      this.customerTypePie = echarts.init(
        document.getElementById("customerTypePie")
      );
      this.vipChargePie = echarts.init(document.getElementById("vipChargePie"));
      this.fifteenFirstReturn = echarts.init(
        document.getElementById("fifteenFirstReturn")
      );
      this.fifteenFifteenRechargeAmt = echarts.init(
        document.getElementById("fifteenFifteenRechargeAmt")
      );
      this.MonthRoi = echarts.init(document.getElementById("MonthRoi"));

      // 监听legend切换事件调用接口切换数据
      this.sevenComparebar.on("legendselectchanged", (e) => {
        switch (e.name) {
          case "消耗":
            this.getSevenDayData(1);
            break;
          case "充值":
            this.getSevenDayData(2);
            break;
          case "新用户":
            this.getSevenDayData(3);
            break;
          case "老用户":
            this.getSevenDayData(4);
            break;
          default:
            break;
        }
      });
      // 监听七日同时段点击时间
      this.sevenComparebar.getZr().on("click", (params) => {
        if (params.target && params.target.name) {
          this.handleShowDrawer("consumeCharging", "hour");
        }
      });
      window.onresize = () => {
        this.bookDistribution.resize();
        this.channelDistribution.resize();
        this.sevenComparebar.resize();
      };
    },

    // 设置书籍消耗图形数据
    setBookOption(data) {
      let tmpData = data.map((item) => {
        return {
          name: item.bookName,
          value: item.cashCostAmt,
          booksId: item.booksId,
        };
      });
      this.bookDistribution.setOption(
        {
          tooltip: {
            trigger: "item",
            formatter: "{b}<br/>{c}万",
          },
          color: [
            "#6273E0",
            "#3F80FF",
            "#AB74E9",
            "#22C7B1",
            "#FF9163",
            "#FFB85E",
            "#00BF8A",
            "#FB817F",
          ],
          series: [
            {
              left: "left",
              width: "100%",
              height: "100%",
              type: "treemap",
              data: tmpData,
              roam: false,
              nodeClick: false,
              breadcrumb: { show: false },
            },
          ],
        },
        true
      );
    },
    // 设置公众号消耗图形数据
    setChannelOption(data) {
      let tmpData = data.map((item) => {
        return {
          name: item.channelName,
          value: item.cashCostAmt,
          accountChannelId: item.accountChannelId,
        };
      });
      this.channelDistribution.setOption(
        {
          tooltip: {
            trigger: "item",
            formatter: "{b}<br/>{c}万",
          },
          color: [
            "#6273E0",
            "#3F80FF",
            "#AB74E9",
            "#22C7B1",
            "#FF9163",
            "#FFB85E",
            "#00BF8A",
            "#FB817F",
          ],
          series: [
            {
              left: "left",
              width: "100%",
              height: "100%",
              type: "treemap",
              data: tmpData,
              roam: false,
              nodeClick: false,
              breadcrumb: { show: false },
            },
          ],
        },
        true
      );
    },
    // 设置七日同时段对比图形数据
    setsevenComparebarOption(data, type) {
      let tmpseries = [
        {
          data: type === 1 ? data.y : [],
          barWidth: 24,
          color: "#579BF4",
          name: "消耗",
          type: "bar",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              label: {
                show: true, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
          },
        },
        {
          data: type === 2 ? data.y : [],
          barWidth: "24",
          color: "#579BF4",
          name: "充值",
          type: "bar",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              label: {
                show: true, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
          },
        },
        {
          data: type === 3 ? data.y : [],
          barWidth: "24",
          color: "#579BF4",
          name: "新用户",
          type: "bar",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              label: {
                show: true, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
          },
        },
        {
          data: type === 4 ? data.y : [],
          barWidth: "24",
          color: "#579BF4",
          name: "老用户",
          type: "bar",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              label: {
                show: true, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
          },
        },
      ];
      this.sevenComparebar.setOption(
        {
          title: {
            subtext: "七日同时段对比图",
            subtextStyle: {
              color: this.subTitleColor,
              fontSize: this.subTitleFontSize,
              // fontWeight: "bold",
            },
          },
          tooltip: {
            trigger: "axis",
            formatter: "{c}万<br/>{b}",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            inactiveColor: "#8492a6",
            textStyle: {
              color: "#579BF4",
              fontSize: 12,
              fontWeight: "bold",
            },
            itemGap: 0,
            right: 10,
            top: "10",
            selected: {
              消耗: type === 1,
              充值: type === 2,
              新用户: type === 3,
              老用户: type === 4,
            },
            data: ["消耗", "充值", "新用户", "老用户"],
            selectedMode: "single",
            icon: "none",
          },
          xAxis: {
            axisTick: {
              show: false,
            },
            type: "category",
            data: data.x,
            axisLabel: {
              fontSize: 8,
            },
            axisLine: {
              lineStyle: {
                color: "#8492A6", //更改坐标轴颜色
              },
            },
            // data: ["巴西", "印尼", "美国", "印度", "中国", "世界人口(万)"],
          },
          yAxis: {
            show: false,
            type: "value",
            boundaryGap: [0, 0.01],
          },
          series: tmpseries,
        },
        true
      );
    },
    // 设置客户充值分布
    setcustomerTypePieOption(data) {
      let tmpData = [
        {
          name: "新客户",
          value: data.newCustomer,
        },
        {
          name: "老客户",
          value: data.oldCustomer,
        },
      ];
      this.customerTypePie.setOption({
        title: {
          subtext: "客户类型充值分布",
          left: "left",
          subtextStyle: {
            color: this.subTitleColor,
            fontSize: this.subTitleFontSize,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}万<br/>{d}%",
        },
        legend: {
          show: false,
          bottom: "0",
          left: "center",
          fontWeight: "bold",
        },
        color: ["#FFB85E", "#579BF4"],
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}万",
                textStyle: {
                  fontWeight: "bold",
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: "5",
                length2: "5",
              },
            },
            data: tmpData,
          },
        ],
      });
    },
    // 设置会员充值分布
    setvipChargePieOption(data) {
      let tmpData = [
        {
          name: "会员",
          value: data.vipRechargeAmt,
        },
        {
          name: "非会员",
          value: data.noVipRechargeAmt,
        },
      ];
      this.vipChargePie.setOption({
        title: {
          subtext: "会员充值分布",
          left: "left",
          subtextStyle: {
            color: this.subTitleColor,
            fontSize: this.subTitleFontSize,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}万<br/>{d}%",
        },
        legend: {
          show: false,
          bottom: "0",
          left: "center",
          fontWeight: "bold",
        },
        color: ["#FFB85E", "#579BF4"],
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}万",
                textStyle: {
                  fontWeight: "bold",
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: "5",
                length2: "5",
              },
            },
            data: tmpData,
          },
        ],
      });
    },
    // 设置15日回本线形图
    setfifteenFirstReturnOption(data) {
      let tmpSeries = data.y.map((item) => {
        return {
          symbol: "none",
          name: item.name,
          type: "line",
          data: item.data,
        };
      });
      this.fifteenFirstReturn.setOption({
        color: ["#579BF4", "#FFB85E"],
        title: {
          subtext: "近15日D1回本率",
          subtextStyle: {
            fontSize: this.subTitleFontSize,
            fontWeight: "bold",
            color: this.subTitleColor,
          },
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{a0}: {c0}%<br />{a1}: {c1}%<br />{b}",
          formatter: function (params) {
            if (params.length > 1) {
              return `${params[0].seriesName}: ${params[0].value}%<br />${params[1].seriesName}: ${params[1].value}%<br />${params[1].name}`;
            } else {
              return `${params[0].seriesName}: ${params[0].value}%<br />${params[0].name}`;
            }
          },
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          icon: "circle",
          right: "10%",
          data: ["D1回本率", "平台30日均线"],
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold",
          },
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: data.x,
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
        },
        yAxis: {
          show: false,
          type: "value",
        },
        series: tmpSeries,
      });
    },
    // 设置近15日充值构成
    setfifteenFifteenRechargeAmt(data) {
      let tmpSeries = data.y.map((item) => {
        return {
          name: item.name,
          stack: "total",
          type: "bar",
          barWidth: 10,
          label: {
            show: false,
          },
          emphasis: {
            focus: "series",
          },
          data: item.data,
          itemStyle: {
            normal: {
              barBorderRadius:
                item.name === "老用户" ? [5, 5, 0, 0] : [0, 0, 0, 0],
            },
          },
        };
      });
      this.fifteenFifteenRechargeAmt.setOption({
        title: {
          subtext: "近15日充值构成",
          subtextStyle: {
            fontSize: this.subTitleFontSize,
            fontWeight: "bold",
            color: this.subTitleColor,
          },
        },
        color: ["#FFB85E", "#579BF4"],
        tooltip: {
          trigger: "axis",
          // formatter: "{a0}: {c0}万<br />{a1}: {c1}万<br />{b}",
          formatter: function (params) {
            if (params.length > 1) {
              return `${params[0].seriesName}: ${params[0].value}万<br />${params[1].seriesName}: ${params[1].value}万<br />${params[1].name}`;
            } else {
              return `${params[0].seriesName}: ${params[0].value}万<br />${params[0].name}`;
            }
          },
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          icon: "circle",
          right: "10%",
          data: ["新用户", "老用户"],
          textStyle: {
            color: "#8492a6",
            fontWeight: "bold",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: data.x,
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
        },
        yAxis: {
          show: false,
          type: "value",
        },
        series: tmpSeries,
      });
    },
    // 设置分月roi柱状图
    setMonthRoiOption(data) {
      let tmpSeries = data.y.map((item) => {
        return {
          symbol: "none",
          color: item.name === "ROI" ? "#579BF4" : "#FFB85E",
          name: item.name,
          barWidth: 20,
          data: item.data,
          type: item.name === "ROI" ? "bar" : "line",
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              lineStyle: {
                width: 2, //设置线条粗细
              },
              label: {
                show: false, //开启显示
                position: "insideTop", //在上方显示
                textStyle: {
                  //数值样式
                  color: "#fff",
                  fontSize: this.subTitleColor,
                },
              },
            },
          },
        };
      });
      this.MonthRoi.setOption(
        {
          title: {
            subtext: "分月ROI",
            subtextStyle: {
              fontSize: 12,
              fontWeight: "bold",
              color: this.subTitleColor,
            },
          },
          tooltip: {
            trigger: "axis",
            formatter: "roi：{c}%<br/>{b}",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
            icon: "circle",
            right: "10%",
            data: ["ROI"],
            textStyle: {
              fontWeight: "bold",
              fontSize: this.subTitleFontSize,
            },
          },
          xAxis: {
            axisLabel: {
              fontSize: 8,
            },
            axisTick: {
              show: false,
            },
            data: data.x,
            axisLine: {
              lineStyle: {
                color: "#8492A6", //更改坐标轴颜色
              },
            },
          },
          yAxis: {
            show: false,
            type: "value",
          },
          series: tmpSeries,
        },
        true
      );
    },

    // echarts相关结束------------------------------------------------>

    // 初始化页面 获取所有数据
    initPage(flag) {
      if (flag === "created") {
        // 缓存获取
        const tmpIndex = this.$store.state.routerHistory.findIndex(
          (item) => item.path === this.$route.path
        );
        // 若存在缓存
        if (tmpIndex !== -1) {
          this.params = this.$store.state.routerHistory[tmpIndex].params;
          // 若无查询时间 添加默认查询时间
          if (!this.params.queryDate) {
            this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
          }
          this.getBookById(this.params.booksId);
        } else {
          // 不存在缓存 初始化筛选条件
          this.params = {
            queryDate: formatTime(new Date(), "yyyy-MM-dd"),
          };
        }
      }
      // 获取今日投放、今日充值、今日消息
      operateManagerPutRecharge(this.params).then((res) => {
        // 今日投放相关
        // this.todayPut = [
        //   {
        //     name:"现金投放",
        //     value:res.cashCostAmt,
        //     change:res.cashCostAmtMounting
        //   },
        //   {
        //     name:"公众号",
        //     value:res.channelNum,
        //     change:res.channelNumMounting
        //   },
        //   {
        //     name:"书籍",
        //     value:res.booksNum,
        //     change:res.booksNumMounting
        //   },
        //   {
        //     name:"广告",
        //     value:res.adNum
        //   },
        // ]
        this.cashCostAmt = res.cashCostAmt;
        this.cashCostAmtMounting = res.cashCostAmtMounting;
        this.channelNum = res.channelNum;
        this.channelNumMounting = res.channelNumMounting;
        this.booksNum = res.booksNum;
        this.booksNumMounting = res.booksNumMounting;
        this.adNum = res.adNum;
        this.customerMessageNum = res.customerMessageNum;
        this.customerMessageMounting = res.customerMessageMounting;
        this.sendMessageNum = res.sendMessageNum;
        this.sendMessageMounting = res.sendMessageMounting;
        // 今日充值信息条相关
        this.todyChargeBar1 = [
          {
            name: "收益",
            value: res.afterTaxTotalRecharge + "万",
          },
          {
            name: "昨日收益",
            value: res.yesterdayTotalRecharge + "万",
          },
          // {
          //   name: "昨日充值",
          //   value: res.yesterdayRecharge + "万",
          // },
          {
            name: "当日充值",
            value: res.currentRecharge + "万",
          },
          {
            name: "环比",
            value: res.compareRecharge + "万",
          },
          {
            name: "增长率",
            value: res.mountingRate,
          },
          {
            name: "粉丝",
            value: res.cumulateUser + "万",
          },
          {
            name: "单粉产值",
            value: res.userPrice,
          },
        ];
        this.todyChargeBar2 = [
          {
            name: "现金投放",
            value: res.cashCostAmt + "万",
          },
          {
            name: "增粉",
            value: res.newUser,
          },
          {
            name: "单粉价",
            value: res.newUserPrice,
          },
          {
            name: "	D1回本",
            value: res.firstAmt + "万",
          },
          {
            name: "	单量",
            value: res.orderCnt,
          },
          {
            name: "	付费人数",
            value: res.payNum,
          },
          {
            name: "	回本率",
            value: res.returnRate + "%",
          },
        ];
        this.todyreturnRate = res.returnRate;
      });
      // 获取书籍、公众号、会员充值、客户类型充值分布消耗
      operateFifteenBCVCCost(this.params).then((res) => {
        this.booksCost = res.booksCost;
        this.channelCost = res.channelCost;
        this.setBookOption(this.booksCost);
        this.setChannelOption(this.channelCost);
        this.setcustomerTypePieOption(res.customerType);
        this.setvipChargePieOption(res.vipRecharge);
        this.channelCost = res.channelCost;
      });
      // 获取七日同时段对比图
      this.getSevenDayData(1, this.params);
      // 获取15日回本率线形图
      operateFifteenFirstReturn(this.params).then((res) => {
        this.setfifteenFirstReturnOption(res);
      });
      // 获取十五日充值分布
      operateFifteenFifteenRechargeAmt(this.params).then((res) => {
        this.setfifteenFifteenRechargeAmt(res);
      });
      // 获取累计回本roi
      this.getoperateTotalReturnRoi(1, this.params);
      // 获取月份roi
      operateMonthRoi(this.params).then((res) => {
        this.setMonthRoiOption(res);
      });
      // 缓存参数
      this.addRouterHistory({
        path: this.$route.path,
        params: this.params,
      });
    },
    getSevenDayData(type, params) {
      operateManagerSevenDayData({
        dataType: type,
        ...this.params,
      }).then((res) => {
        this.sevenCompareData = res;
        this.setsevenComparebarOption(this.sevenCompareData, type);
      });
    },
    // 获取累计回本
    getoperateTotalReturnRoi(type, params) {
      let beginDate = null;
      let endDate = null;
      switch (type) {
        case 2:
          beginDate = getPreMonth()[0];
          endDate = getPreMonth()[1];
          break;
        case 3:
          beginDate = getPresentMonth()[0];
          endDate = getPresentMonth()[1];
          break;
        case 4:
          beginDate = getRecentlyMonth()[0];
          endDate = getRecentlyMonth()[1];
          break;
        default:
          beginDate = null;
          endDate = null;
          break;
      }
      operateTotalReturnRoi({
        dataType: this.dataType,
        beginDate,
        endDate,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        ...this.params,
      }).then((res) => {
        this.channelsPage = 1;
        this.booksPage = 1;
        this.totalReturnRoi = [
          {
            name: "投放",
            value: res.putAmt,
          },
          {
            name: "回本",
            value: res.returnAmt,
          },
          {
            name: "利润",
            value: res.profitAmt,
          },
          {
            name: "日充",
            value: res.everydayRecharge,
          },
          {
            name: "roi",
            value: res.roi,
          },
        ];
        this.booksRoi = res.booksRoi;
        this.booksRoiPageData = this.booksRoi.slice(0, this.booksRoiPageSize);
        this.booksRoiTotalPage = Math.ceil(
          this.booksRoi.length / this.booksRoiPageSize
        );
        this.channelsRoi = res.channelsRoi;
        this.channelsRoiPageData = this.channelsRoi.slice(
          0,
          this.channelsRoiPageSize
        );
        this.channelsRoiTotalPage = Math.ceil(
          this.channelsRoi.length / this.channelsRoiPageSize
        );
      });
    },
    initEchartsEvent() {
      this.bookDistribution.on("click", "series", (params) => {
        this.params.booksId = params.data.booksId;
        this.getBookById(this.params.booksId);
        this.handlePageChange();
      });
      this.channelDistribution.on("click", "series", (params) => {
        this.params.accountChannelId = params.data.accountChannelId;
        this.handlePageChange();
      });
    },
  },
  async mounted() {
    await this.initEchart();
    await this.initEchartsEvent();
  },
  created() {
    this.initPage("created");
    this.getBook();
  },
};
</script>

<style lang="scss" scoped>
.filter-box {
  padding-bottom: 0px !important;
  // position: fixed;
  // top: 90px;
  // left: 274px;
  // z-index: 99999;
}
.board-main {
  // margin-top: 41px;
}

.returnRate {
  color: #ffa700 !important;
}

.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
#test,
#bartest {
  width: 500px;
  height: 500px;
}
// 今日投放
.el-card {
  border: none;
  /deep/ .el-card__header {
    padding: 12px 0;
  }
  /deep/ .el-card__body {
    padding: 0;
  }
}
.el-card .card-title {
  display: inline-block;
  color: #969696;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
.put-item-goto {
  cursor: pointer;
}
.put-item-goto:hover {
  // box-shadow: 5px 5px 5px #888888;
}
.put-item {
  padding: 4px 0;
  // display: flex;
  // align-items: center;
  h4 {
    height: 14px;
    line-height: 14px;
    color: #475669;
    font-size: 14px;
    font-weight: 400;
    padding-left: 7px;
    border-radius: 2px;
  }
  .put-1 {
    border-left: 4px solid #3f80ff;
  }
  .put-2 {
    border-left: 4px solid #22c7b1;
  }
  .put-3 {
    border-left: 4px solid #ffb85e;
  }
  .put-4 {
    border-left: 4px solid #6273e0;
  }
  .put-item-value {
    padding: 5px 0 5px 10px;
    // padding-right:0;
    .icon-xiajiang {
      font-size: 12px;
    }
  }
  .total-value {
    font-size: 24px;
    font-weight: bold;
    color: #1f2d3d;
  }
  .change-value-red {
    margin-left: auto;
    font-size: 12px;
    color: #fd5d5a;
  }
  .change-value-green {
    margin-left: auto;
    font-size: 12px;
    color: #22c7b1;
  }
}
.map-tree-box {
  #bookDistribution,
  #channelDistribution {
    min-height: 200px;
  }
  .title {
    color: #969696;
    font-size: 14px;
    font-weight: bold;
    margin: 14px 0;
  }
}
#sevenCompare {
  height: 245px;
}

// 数据条样式
.charge-bar-goto {
  cursor: pointer;
}
.charge-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bar-item1,
  .bar-item3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #8492a6;
    }
    .value {
      // color: #579BF4;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .bar-item3 {
    .value {
      font-size: 14px;
    }
  }
  .bar-item2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 12px;
      color: #8492a6;
    }
    .value {
      // color: #579BF4;
      font-size: 12px;
      font-weight: bold;
    }
    .returnRate {
      color: #ffa700;
    }
  }
}
// 饼状图样式
#customerTypePie,
#vipChargePie {
  height: 200px;
}
// 15日回本线性图
#fifteenFirstReturn {
  height: 150px;
}
// 15日充值构成
#fifteenFifteenRechargeAmt {
  height: 180px;
}

// 累计回本
.total-return {
  position: relative;
  .card-title {
    // padding: 0 40px;
    .title {
      font-weight: bold;
    }
  }
  .card-title-tab {
    position: absolute;
    top: 10px;
    right: 20px;
    .active {
      color: #579bf4;
    }
    span {
      padding-right: 25px;
      font-size: 12px;
      color: #8492a6;
      cursor: pointer;
    }
  }
  .el-link {
    padding: 0 5px;
  }

  #MonthRoi {
    height: 200px;
  }
  // 书籍roi表格
  .books-roi {
    .title {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-text {
        color: #1f2d3d;
        font-size: 16px;
        font-weight: bold;
      }
      .change-page {
        margin-left: 20px;
        /deep/ .el-button--mini {
          padding: 0px 3px !important;
          .el-icon--right {
            margin: 0 !important;
          }
        }
        .page-value {
          margin-right: 5px;
          font-size: 12px;
          text-align: center;
          margin-top: 1px;
          color: #8492a6;
        }
      }
    }
    /deep/ td {
      padding: 5px !important;
    }
    /deep/ .is-leaf {
      padding: 0 !important;
    }
    /deep/ .has-gutter tr {
      height: 30px !important;
    }
    /deep/ tr td {
      font-size: 12px !important;
    }
    /deep/ thead tr th {
      font-size: 12px !important;
    }
    /deep/ th > .cell {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}
.el-table {
  color: rgb(150, 150, 150) !important;
}
// 杜邦
.duPont-drawer /deep/ .el-drawer__header {
  margin-bottom: 0 !important;
}
.duPont-drawer /deep/ .el-drawer__header > :first-child {
  text-align: center;
}
</style>
